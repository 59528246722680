import React from "react";

export const AboutEnUs = () => {
  return (
    <>
      <p>
        WINPR (World Index of Patient Groups for Rare Diseases) is a website
        that allows you to cross-search national and international patient
        organizations by disease name in each language.
      </p>

      <p>
        It is estimated that there are about 300 million patients with rare
        diseases worldwide, and because of their small numbers, they have not
        been adequately studied and treatments have not been established. In
        addition, many patients and their families have been unable to exchange
        information and interact with patients and their families with the same
        disease and have been left with anxiety.
      </p>

      <p>
        Under these circumstances, various international collaborations among
        researchers, regulatory authorities and so on in the field of rare
        diseases have been expanding in recent years. Patient organizations are
        also beginning to understand the importance of promoting cross-country
        collaboration to share the valuable experiences and information of
        patients from all over the world.
      </p>

      <p>
        With the aim of furthering this kind of collaboration and connecting
        patient organizations around the world, we have released the WINPR
        website.
      </p>

      <p>
        WINPR is the first place you can use your native language to get to know
        your overseas patient organization. If you would like to make further
        contact, you can go directly to the information listed. You can also
        contact “the Data Provider agency” that provided the individual patient
        groups information.
      </p>

      <p>
        Connect with overseas patient organizations in your native language
        through the WINPR. First, try typing the name of the disease in your
        native language!
      </p>

      <p>
        The pre-release phase of WINPR is targeted at Northeast Asian countries.
        We will then consider expanding to Asia Pacific and other regions in a
        phased manner. We also plan to share not only information on patient
        organizations, but also information on rare diseases, including national
        legal measures.
      </p>
      <p>
        This website was implemented as a non-profit research project with the
        support of UCB Japan Co., Ltd.. We would like to express our deepest
        gratitude to all of you for your cooperation and support.
      </p>
    </>
  );
};
