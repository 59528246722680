import React from "react";
import { useI18n } from "../i18n";
import { PolicyJaJp } from "./PrivacyPolicy/JaJp";
import { PolicyEnUs } from "./PrivacyPolicy/EnUs";

const Registry = new Map<string, JSX.Element>();
Registry.set("en_us", <PolicyEnUs />);
Registry.set("ja_jp", <PolicyJaJp />);

export const PrivacyPolicy = () => {
  const { i18n } = useI18n();
  return <>{Registry.get(i18n.language) || <PolicyEnUs />}</>;
};
