import React, { useState, useEffect } from "react";
import "./App.css";
import { useI18n, langLabels, availableLanguages } from "./i18n";
import { DiseaseSearchForm } from "./components/DiseaseSearchForm";
import { SearchResult } from "./types";
import { DiseaseList } from "./components/DiseaseList";
import { About } from "./components/About";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { OrganizationList } from "./components/OrganizationList";
import { Menu, Dropdown, Image, Container, Segment } from "semantic-ui-react";

const App = () => {
  const { t, i18n } = useI18n();

  useEffect(() => {
    const navigatorLang =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language;

    let matchedLang = availableLanguages.find((elem) =>
      elem.match(navigatorLang)
    );

    if (matchedLang) {
      i18n.changeLanguage(matchedLang);
    }
    // eslint-disable-next-line
  }, []);

  const handleLangMenuClick = (lang: string) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      i18n.changeLanguage(lang);
    };
  };

  const initialSearchResult: SearchResult = {
    organizations: [],
    diseases: [],
    searchWord: "",
  };
  const [result, setResult] = useState<SearchResult>(initialSearchResult);

  const style = {
    tagline: {
      fontSize: "1.5em",
    },
  };
  return (
    <>
      <Container>
        <Menu size="small" borderless>
          <Menu.Item>
            <a href="/">
              <Image src="/logo.png" alt="WINPR" />
            </a>
          </Menu.Item>
          <Menu.Item style={style.tagline}>
            World Index of Patient Groups for Rare Diseases
          </Menu.Item>
          <Menu.Menu position="right">
            <Dropdown item text={t("app.language")}>
              <Dropdown.Menu>
                {availableLanguages.map<JSX.Element>((lng: string) => (
                  <Dropdown.Item key={lng} onClick={handleLangMenuClick(lng)}>
                    {langLabels.get(lng)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>

        <Segment.Group>
          <Segment>
            <DiseaseSearchForm setResult={setResult} />
          </Segment>
          {result.diseases.length > 0 ? (
            <>
              <Segment>
                <DiseaseList result={result} />
              </Segment>
              <Segment>
                <OrganizationList result={result} />
              </Segment>
            </>
          ) : (
            <>
              <Segment>
                <About />
                <PrivacyPolicy />
              </Segment>
            </>
          )}
        </Segment.Group>
        <footer>
          © {new Date().getFullYear()} <a href="https://asrid.org">NPO ASrid</a>
          . All Rights Reserved.
        </footer>
      </Container>
    </>
  );
};

export default App;
