import React from "react";
import { DiseaseResultMap } from "../types";

interface FoundDiseaseListProps {
  diseaseMap: DiseaseResultMap;
}

// TODO: dangerouslySetInnerHTML 無しでうごくようにする
export const FoundDiseaseList = (props: FoundDiseaseListProps) => {
  const dict = props.diseaseMap;
  const messageSuffix = dict.size > 1 ? "diseases" : "disease";
  const message = `Matched ${dict.size} ${messageSuffix}`;
  return (
    <>
      <p>{message}</p>
      {Array.from(dict).map((elem) => (
        <li
          key={elem[0]}
          dangerouslySetInnerHTML={{
            __html: elem[1],
          }}
        ></li>
      ))}
    </>
  );
};
