// cli/typesからコピーしてきたものをCRAで動くように改変している。
export type DiseaseId = string;
export type OrganizationId = string;

export interface PatientOrganization {
  id: OrganizationId;
  organizationName: string;
  organizationEnglishName: string;
  region: string;
  state: string;
  provider: Provider;
  url: string;
  diseases: Array<Disease>;
}

export enum Provider {
  TFRD = "tfrd",
  HKARD = "hkard",
  JPA = "jpa",
  NETWORK = "network",
  JPA_NETWORK = "jpa_network",
}

export interface Disease {
  id: DiseaseId;
  organizationId: OrganizationId;
  lang: string;
  name: string;
  nameInLowerCase: string;
  translation: string;
}

export type PatientOrganizationKey = keyof PatientOrganization;
