import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en_us: {
        translation: {
          app: {
            language: "Language",
          },
          disease_search_form: {
            placeholder: "e.g: ALS,Syndrome",
            disease_name: "Disease name (partial match)",
            search_button: "Search",
          },
        },
      },
      ja_jp: {
        translation: {
          app: {
            language: "言語",
          },
          disease_search_form: {
            placeholder: "例: ALS, 症候群",
            disease_name: "疾患名（部分検索）",
            search_button: "検索",
          },
        },
      },
    },
    lng: "en_us",
    fallbackLng: "en_us",

    interpolation: {
      escapeValue: false,
    },
  });

export const availableLanguages = ["en_us", "ja_jp"];
i18n.languages = availableLanguages;

const labels = new Map<string, string>();
labels.set("en_us", "English");
labels.set("ja_jp", "日本語");

export const useI18n = useTranslation;
export const langLabels = labels;
