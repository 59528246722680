import React from "react";

export const AboutJaJp = () => {
  return (
    <>
      <p>
        WINPR(ウィンプル、World Index of Patient Groups for Rare Diseases)は
        、疾患名から、日本や外国の患者組織を各国語で相互検索できるウェブサイトです。
      </p>
      <p>
        世界に約3億人存在すると推定される希少疾患患者は、その数が少ないために、研究が十分に行われず、治療法も確立されていません。また、患者や家族の多くが、同じ病気の患者との情報交換、交流もままならず、不安を抱えてきました。
      </p>

      <p>
        こうした状況のなか、近年、希少疾患領域において、研究者間や当局間などで、様々な国際連携が広がっています。患者組織においても、国を超えた連携を進めることで、世界各国に点在する患者の貴重な経験や情報を共有することの重要性が理解されつつあります。
        <br />
        こういった連携をさらに進め、世界中の患者会が「つながる」ことを目的として、WINPRを公開しました。
      </p>

      <p>
        WINPRは、まずは母国語を使って、海外患者組織を知ることができます。さらにコンタクトをとりたい場合には、掲載された情報に直接アクセスが可能です。また、個別情報の提供元であるData
        Provider機関に連絡をとることも可能です。
      </p>

      <p>
        WINPRを通じて、母国語で海外患者組織とつながってみませんか。
        まずは、母国語で病名を入力してみてください！
      </p>

      <p>
        ＊WINPRはプレリリース段階の対象地域は北東アジア諸国とします。その後、状況を確認しつつアジア・パシフィック、他地域への拡大を検討します。
        <br />
        また、患者会情報だけでなく、各国法施策をはじめとした希少疾患情報の共有も行う予定です。
      </p>

      <p>
        本ウェブサイトは、非営利型研究プロジェクトとして、ユーシービージャパン株式会社のご支援をいただき実施しました。ご協力・ご支援いただきました皆様に深く御礼申し上げます。
      </p>
    </>
  );
};
