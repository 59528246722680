import React from "react";

export const PolicyJaJp = () => {
  return (
    <>
      <h2>プライバシーポリシー</h2>
      <p>
        WINPRは現在プレリリース期間中です。正式リリース時にはプライバシーポリシーの更新が予定されています。
      </p>

      <h4>利用者から取得する情報とその利用について</h4>
      <p>
        WINPRでは利用者が入力した文字列（以下、検索キーワード）を運営組織内でサービス開発の動作確認や調査にのために取得します。
      </p>

      <h4>Cookie（クッキー）の利用について</h4>
      <p>WINPRではCookie（サードパーティー含む）を利用しません。</p>

      <h4>取得したデータの共有について</h4>
      <p>WINPRでは取得したデータを運営元組織でのみ利用します。</p>
    </>
  );
};
