import React, { useState } from "react";
import { Button, Form, Loader } from "semantic-ui-react";
import { SearchResult } from "../types";
import { Config } from "../config";
import { debug } from "../utils/debug";
import { useI18n } from "../i18n";

interface DiseaseSearchFormProps {
  setResult: Function;
}

export const DiseaseSearchForm = (props: DiseaseSearchFormProps) => {
  const { t } = useI18n();
  const [searchWord, setSearchWord] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const endpoint = Config.endpoint;
  const url = endpoint + `organizations?searchWord=${searchWord}`;

  const query = () => {
    setLoading(true);

    fetch(url, {
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data: SearchResult) => {
        debug(data);
        props.setResult({
          ...data,
          searchWord,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div className="DiseaseSearchForm">
        <Form onSubmit={query}>
          <Form.Field>
            <label>{t("disease_search_form.disease_name")}</label>
            <input
              placeholder={t("disease_search_form.placeholder")}
              value={searchWord}
              disabled={loading}
              onChange={(e) => setSearchWord(e.currentTarget.value)}
            />
          </Form.Field>
          <Button type="submit" disabled={loading}>
            {t("disease_search_form.search_button")}
          </Button>
          <Loader active={loading} />
        </Form>
      </div>
    </>
  );
};
