import React from "react";
import { SearchResult, Organization } from "../types";
import { Grid, Divider } from "semantic-ui-react";
import { ProviderDetail } from "./ProviderDetail";
import { DiseaseList } from "./OrganizationList/DiseaseList";

interface OrganizationListProps {
  result: SearchResult;
}

export const OrganizationList = (props: OrganizationListProps) => {
  const result = props.result;
  const organizations = result.organizations;

  const styles = {
    orgName: {
      marginBottom: "0.1em",
    },
    sectionTitle: {
      marginBottom: "5px",
    },
    englishName: {
      margin: "0 0 0.2em 0",
    },
    url: {
      margin: "0 0 0.2em 0",
    },
  };

  const englishName = (name: string) => (
    <p style={styles.englishName}>{name}</p>
  );

  const location = (org: Organization) => {
    if (org.region === org.state || org.state.trim().length === 0) {
      return org.region;
    } else {
      return (
        <>
          {org.region} / {org.state}
        </>
      );
    }
  };

  let organizationItems = organizations.map((org, index) => (
    <div key={index}>
      <Divider />
      <Grid>
        <div className="sixteen wide column">
          <h2 style={styles.orgName}>{org.organizationName}</h2>
          {!!org.organizationEnglishName &&
            englishName(org.organizationEnglishName)}
          <p style={styles.url}>
            <a href={org.url}>{org.url}</a>
          </p>
        </div>
      </Grid>
      <Grid>
        <div className="eight wide column">
          <h4 style={styles.sectionTitle}>Location</h4>
          <p>{location(org)}</p>
          <h4 style={styles.sectionTitle}>Data Provider Information</h4>
          <ProviderDetail providerKey={org.provider} />
        </div>
        <div className="eight wide column">
          <h4 style={styles.sectionTitle}>Target diseases</h4>
          <DiseaseList diseases={org.diseases} />
        </div>
      </Grid>
    </div>
  ));

  const organizationSuffix =
    organizations.length > 1 ? "organizations" : "organization";

  const foundMessage = `Found ${organizations.length} ${organizationSuffix} for matched diseases`;

  return (
    <>
      <p>{!!result.searchWord && foundMessage}</p>
      {organizationItems}
    </>
  );
};
