import React from "react";
import { Provider } from "../types/cli";

interface ProviderDetailProps {
  providerKey: Provider;
}

interface ProviderDetailContent {
  id: Provider;
  name: string;
  abbr?: string;
  localName: string;
  url: string;
}

interface ProviderDetailContentProps {
  provider: ProviderDetailContent;
}

const Providers: ProviderDetailContent[] = [
  {
    id: Provider.HKARD,
    name: "Hong Kong Alliance for Rare Diseases",
    localName: "香港罕見疾病聯盟",
    abbr: "HKARD",
    url: "https://www.hkard.org/",
  },
  {
    id: Provider.TFRD,
    name: "Taiwan Foundation for Rare Disorders",
    localName: "財團法人罕見疾病基金會",
    abbr: "TFRD",
    url: "http://www.tfrd.org.tw/",
  },
  {
    id: Provider.JPA,
    name: "Japan Patients Association",
    localName: "一般社団法人 日本難病・疾病団体協議会",
    abbr: "JPA",
    url: "https://nanbyo.jp/",
  },
  {
    id: Provider.NETWORK,
    name: "The Pediatric Nanbyo Support Network",
    localName: "難病のこども支援全国ネットワーク",
    url: "https://www.nanbyonet.or.jp/",
  },
];

const ProviderDetailContent = (props: ProviderDetailContentProps) => {
  const provider = props.provider;

  return (
    <>
      <div className="providerName">
        {provider.name}
        {!!provider.abbr && ` (${provider.abbr})`} {"/"} {provider.localName}{" "}
        <a href={provider.url}>{provider.url}</a>
      </div>
    </>
  );
};

export const ProviderDetail = (props: ProviderDetailProps) => {
  const providerKey = props.providerKey;

  let providers: JSX.Element[] = [];

  switch (providerKey) {
    case Provider.JPA_NETWORK:
      const jpa = Providers.find((elem) => elem.id === Provider.JPA);
      if (!jpa) {
        throw new Error("Undefined provider for key: jpa");
      }
      providers.push(
        <ProviderDetailContent provider={jpa} key={Provider.JPA} />
      );

      const network = Providers.find((elem) => elem.id === Provider.NETWORK);
      if (!network) {
        throw new Error("Undefined provider for key: jpa");
      }
      providers.push(
        <ProviderDetailContent provider={network} key={Provider.NETWORK} />
      );

      break;
    default:
      const p = Providers.find((elem) => elem.id === providerKey);

      if (!p) {
        throw new Error("Undefined provider for key: " + providerKey);
      }

      providers.push(<ProviderDetailContent provider={p} key={providerKey} />);
      break;
  }

  return <>{providers.map((elem) => elem)}</>;
};
