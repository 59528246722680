import React, { useState } from "react";
import { Disease } from "../../types";

interface OrgDiseaseListProps {
  diseases: Disease[];
}

export const DiseaseList = (props: OrgDiseaseListProps) => {
  const styles = {
    seeMore: {
      cursor: "pointer",
      marginLeft: "20px",
    },
    list: {
      paddingLeft: "40x",
      marginTop: "0",
      marginBottom: "0.5em",
    },
  };

  const diseases = props.diseases;

  const [diseaseList, setDiseaseList] = useState(
    Array.from(diseases).slice(0, 9)
  );

  const showAllDisease = () => {
    setDiseaseList(Array.from(diseases));
  };

  return (
    <>
      <ul style={styles.list}>
        {diseaseList.map((d) => (
          <li key={d.id}>
            {d.name}
            {d.translation && `= ${d.translation}`}
          </li>
        ))}
      </ul>
      {diseaseList.length < diseases.length && (
        <button
          className="ui mini button"
          onClick={showAllDisease}
          style={styles.seeMore}
        >
          Show more
        </button>
      )}
    </>
  );
};
