import React from "react";
import { Disease, DiseaseId, SearchResult, DiseaseResultMap } from "../types";
import { FoundDiseaseList } from "./FoundDiseaseList";
import { debug } from "../utils/debug";

interface DiseaseListProps {
  result: SearchResult;
}

function registerIfMatched(
  dic: DiseaseResultMap,
  disease: Disease,
  prop: keyof Disease,
  word: string
): boolean {
  const target: string = disease[prop];
  const keyword = new RegExp(word, "i");

  if ((target.match(keyword) as RegExpMatchArray) !== null) {
    const highlightedTarget = target.replace(
      keyword,
      '<span class="highlight">$&</span>'
    );

    dic.set(disease.id, highlightedTarget);
    return true;
  }

  return false;
}

const matchedDiseaseFilter = (result: SearchResult): DiseaseResultMap => {
  const dic = new Map<DiseaseId, string>();
  const word = result.searchWord;

  for (let d of result.diseases) {
    if (dic.has(d.id)) continue;

    if (registerIfMatched(dic, d, "name", word)) continue;
    if (registerIfMatched(dic, d, "translation", word)) continue;
  }

  return dic;
};

export const DiseaseList = (props: DiseaseListProps) => {
  const result = props.result;
  const diseaseMap = matchedDiseaseFilter(props.result);
  debug(diseaseMap);

  const noResultFoundMessage = <div>No results</div>;

  return (
    <>
      {diseaseMap.size > 0 ? (
        <FoundDiseaseList diseaseMap={diseaseMap} />
      ) : (
        !!result.searchWord && noResultFoundMessage
      )}
    </>
  );
};
