import React from "react";
import { useI18n } from "../i18n";
import { AboutJaJp } from "./About/JaJp";
import { AboutEnUs } from "./About/EnUs";

const AboutRegistry = new Map<string, JSX.Element>();
AboutRegistry.set("en_us", <AboutEnUs />);
AboutRegistry.set("ja_jp", <AboutJaJp />);

export const About = () => {
  const { i18n } = useI18n();

  return (
    <>
      <h3>About WINPR</h3>
      {AboutRegistry.get(i18n.language)}
    </>
  );
};
