import React from "react";

export const PolicyEnUs = () => {
  return (
    <>
      <h2>Privacy Policy</h2>
      <p>
        Notice: WINPR is currently under pre-release. We will update our privacy
        policy when we officially release WINPR.
      </p>

      <h4>Data collections and uses</h4>
      <p>WINPR collects search words to improve our service</p>

      <h4>Cookies and third-party technologies</h4>
      <p>WINPR does not use any cookies.</p>

      <h4>Data sharing</h4>
      <p>WINPR does not share data to third-parties.</p>
    </>
  );
};
